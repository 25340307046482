import { useEffect } from 'react';
import { navigate } from 'gatsby';
import { findFirst } from 'fp-ts/lib/Array';
import { match } from 'fp-ts/lib/Option';
import { identity, pipe } from 'fp-ts/lib/function';

const getTargetLanguages = (languages: string[], navigatorLanguage: string) => pipe(
  languages,
  findFirst((lang: string) => lang.startsWith(navigatorLanguage)),
  match(
    () => 'en',
    identity,
  ),
);

export function useLanguageNavigateEffect(languages: string[]) {
  useEffect(() => {
    const [navigatorLanguage] = window.navigator.language.split('-');
    const targetLanguage = getTargetLanguages(languages, navigatorLanguage);

    const languagePath = window.location.pathname.split('/')[1];
    const restPath = window.location.pathname.split('/').slice(2).join('/');

    if (!languages.includes(languagePath)) {
      void navigate(
        `/${targetLanguage}/${restPath}`,
        { replace: true },
      );
      return;
    }

    void navigate(`/${targetLanguage}/`, { replace: true });
  }, [languages]);
}
